import "../../../assets/styles/organisation.scss";
import DetailsPane from "./DetailsPane";

const Organisation = () => {
  return (
    <>
      <div id="organisation">
        <DetailsPane />
      </div>
    </>
  );
};

export default Organisation;
