import { useEffect, useState } from "react";
import { Form, Input, Button, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import CopyButton from "../../common/widgets/CopyButton";
import MaskButton from "../../common/widgets/MaskButton";
import { maskText } from "../../../utils/helpers";
import { useReload } from "../../../hooks/useReload";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import MispIcon from "../../../assets/svgs/entities/link-MISP.svg";

const inputIds = {
  API_KEY: "api_key",
  MISP_API_KEY: "client_misp_api_key",
  MISP_IP: "client_misp_ip_address",
  MISP_ALLOWED_IP: "misp_allow_list_ip_address",
};

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, marginRight: 10 }} spin />
);

const AccessDetailsForm = () => {
  const [organisationDetails, setOrganisationDetails] = useState(null);
  const [mispAPIKeyMask, setMispAPIKeyMask] = useState(true);
  const [mispAPIAddressMask, setMispAPIAddressMask] = useState(true);
  const [configure, setConfigure] = useState(false);

  const [{ loading, result }, getOrganisationData] = useAxios();
  const [
    { loading: updating, result: updateRes, error: updateErr },
    updateOrgRequest,
  ] = useAxios(null, api.methods.PUT);

  useEffect(() => getOrganisationData(api.ORGANISATIONS_ENDPOINTS), []);
  useMessageBoxError(updateErr);
  useReload(result, () => setOrganisationDetails(result.data));
  useReload(updateRes, () => getOrganisationData(api.ORGANISATIONS_ENDPOINTS));

  const { client_misp_api_key, client_misp_ip_address, misp_enabled } =
    organisationDetails || {};

  const maskOptions = { showPrefix: 2, showSuffix: 2 };
  const maskedMispKey = maskText(client_misp_api_key, maskOptions);
  const maskedMispAddress = maskText(client_misp_ip_address, maskOptions);

  useEffect(() => {
    if (configure) {
      setMispAPIKeyMask(false);
      setMispAPIAddressMask(false);
    } else {
      setMispAPIKeyMask(true);
      setMispAPIAddressMask(true);
    }
  }, [configure]);

  const handleInputChange = (e) => {
    setOrganisationDetails((data) => ({
      ...data,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSave = () => {
    const payload = {
      client_misp_api_key,
      client_misp_ip_address,
      misp_enabled,
    };
    updateOrgRequest(api.ORGANISATIONS_MISP, payload);
  };

  const isClean = _.isEqual(organisationDetails, result.data);

  return (
    <>
      <div className="profiles">
        <div className="profiles-head">
          <img src={MispIcon} alt="link-misp" height={16} width={16} />
          <div className="profiles-head-infos">MISP</div>
        </div>

        <div className="profiles-holder">
          <div className="profiles-holder-switch">
            <div className="profiles-holder-switch-button">
              Configure
              <Switch
                checked={configure}
                className="profiles-holder-switch-button-toggle"
                onClick={() => {
                  setConfigure((enable) => !enable);
                }}
                style={{ backgroundColor: !configure ? "#EAECF0" : "#12B76A" }}
              />
            </div>
            <div className="profiles-holder-switch-button">
              Push STIX Bundle
              <Switch
                className="profiles-holder-switch-button-toggle"
                checked={misp_enabled}
                onChange={(checked) => {
                  setOrganisationDetails({
                    ...organisationDetails,
                    misp_enabled: checked,
                  });
                }}
                style={{
                  backgroundColor: !misp_enabled ? "#EAECF0" : "#12B76A",
                }}
              />
            </div>
          </div>
          <Form layout="vertical" className="profiles-holder-form">
            <Form.Item
              label="Client MISP API Key"
              className="profiles-holder-form-label"
            >
              <Input
                id={inputIds.MISP_API_KEY}
                value={mispAPIKeyMask ? maskedMispKey : client_misp_api_key}
                placeholder="Client MISP API Key"
                onChange={handleInputChange}
                disabled={!configure}
                className="profiles-holder-form-label-input"
                addonBefore={
                  <MaskButton
                    mask={mispAPIKeyMask}
                    clickHandler={setMispAPIKeyMask}
                  />
                }
                addonAfter={<CopyButton text={client_misp_api_key} />}
              />
            </Form.Item>
            <Form.Item
              label="Client MISP IP Address"
              className="profiles-holder-form-label"
            >
              <Input
                id={inputIds.MISP_IP}
                value={
                  mispAPIAddressMask
                    ? maskedMispAddress
                    : client_misp_ip_address
                }
                placeholder="Client MISP IP Address"
                onChange={handleInputChange}
                disabled={!configure}
                className="profiles-holder-form-label-input"
                addonBefore={
                  <MaskButton
                    mask={mispAPIAddressMask}
                    clickHandler={setMispAPIAddressMask}
                  />
                }
                addonAfter={<CopyButton text={client_misp_ip_address} />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                disabled={isClean || loading}
                onClick={handleSave}
                className="profiles-holder-form-label-button"
              >
                {updating && <Spin indicator={antIcon} />} Save changes
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AccessDetailsForm;
