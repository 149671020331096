import { useDispatch } from "react-redux";
import { METADATA_KEYS, METADATA_PROFILES } from "../../../constants";
import { showSlideInView } from "../../../store/actions";
import {
  deleteOrganisationMetadataKey,
  deleteOrganisationMetadataProfile,
  updateOrganisationMetadataKeys,
  updateOrganisationMetadataProfile,
} from "../../../store/actions/metadata";
import EditableTable from "../../common/tables/EditableTable";
import WarningDialog from "../../common/widgets/WarningDialog";
import ProfileDetails from "./ProfileDetails";

const addActionNodes = (dispatch, activeTab) => (record, closeDropdown) => {
  const deleteRequest =
    activeTab === METADATA_KEYS
      ? deleteOrganisationMetadataKey
      : deleteOrganisationMetadataProfile;
  return (
    <WarningDialog
      title="Delete this record?"
      onConfirm={() => {
        dispatch(deleteRequest(record.key));
      }}
      closeDropdown={closeDropdown}
    >
      <div
        className="menu-item-rules delete hover"
        style={{ cursor: "pointer", display: "inline-block", width: "100%" }}
        onClick={() => closeDropdown()}
      >
        <span className="menu-item-dropdown-delete">Delete</span>
      </div>
    </WarningDialog>
  );
};

const MetadataTable = ({ columns, data = [], activeTab }) => {
  const dispatch = useDispatch();

  const handleSave = (fields, record) => {
    let payload = { ...record, ...fields };

    if (activeTab === METADATA_KEYS) {
      dispatch(updateOrganisationMetadataKeys([payload]));
    } else {
      payload.keys = [];
      dispatch(updateOrganisationMetadataProfile(record.key, payload));
    }
  };

  const editError = (error) => {
    console.log("eroor", error);
  };

  let handleEdit;
  if (activeTab === METADATA_PROFILES) {
    handleEdit = (record) => {
      dispatch(
        showSlideInView(
          ProfileDetails,
          { record },
          { title: "Profile Details", width: 500 }
        )
      );
    };
  }

  return (
    <EditableTable
      data={data}
      columns={columns}
      addActionNodes={addActionNodes(dispatch, activeTab)}
      saveEditHandler={handleSave}
      editErrorHandler={editError}
      editHandler={handleEdit}
      activeTab
    />
  );
};

export default MetadataTable;
