import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import WarningDialog from "../../common/widgets/WarningDialog";
import { handleDeleteDocument } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import DocumentCollections from "../collections/DocumentCollections";
import { showSlideInView } from "../../../store/actions";
import routes from "../../../constants/routes";
import { deleteGraph } from "../../../store/actions/graphActions";
export const MoreMenu = ({ document, updateDoc, closeDropdown }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { document_id } = document;

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );
  const graphUrl = routes.STIX_GRAPH.replace(":documentId", document_id);

  let sourceUrl = document.source;
  if (!sourceUrl || `${document.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document_id);
  }

  return (
    <Menu onClick={closeDropdown} className="menu-dropdown-head">
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(analysisUrl);
        }}
        key="annotation-view"
      >
        Annotation view
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(graphUrl);
        }}
        key="graph-view"
      >
        Graph view
      </Menu.Item>
      <Menu.Item key="view-source" className="menu-item-dropdown">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={sourceUrl}
          className="menu-item-dropdown"
        >
          View source
        </a>
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        key="manage-collections"
        onClick={() => {
          dispatch(
            showSlideInView(
              DocumentCollections,
              { documentId: document_id, documentTitle: document.title },
              { title: "Manage Collections" }
            )
          );
        }}
      >
        Manage Collections
      </Menu.Item>

      <Menu.Item key="delete" className="menu-item-dropdown">
        <WarningDialog
          title="Delete report"
          onConfirm={() => {
            dispatch(deleteGraph(document_id));
            handleDeleteDocument(document_id, updateDoc);
          }}
          okText="Delete"
          cancelText="Cancel"
          closeDropdown={closeDropdown}
          text="textConfirmation"
        >
          <div className="menu-item-dropdown-delete">Delete</div>
        </WarningDialog>
      </Menu.Item>
    </Menu>
  );
};

export const MoreMenu1 = ({ document, updateDoc, closeDropdown }) => {
  const history = useHistory();
  const { document_id } = document;
  const dispatch = useDispatch();

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );

  const graphUrl = routes.STIX_GRAPH.replace(":documentId", document_id);

  let sourceUrl = document.source;
  if (!sourceUrl || `${document.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document_id);
  }

  return (
    <Menu className="menu" onClick={closeDropdown}>
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(analysisUrl);
        }}
        key="annotation-view"
      >
        Annotation view
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(graphUrl);
        }}
        key="graph-view"
      >
        Graph view
      </Menu.Item>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={sourceUrl}
        className="menu-item-dropdown"
      >
        <Menu.Item key="view-source" className="menu-item-dropdown">
          View source
        </Menu.Item>
      </a>
      <Menu.Item key="delete" className="menu-item-dropdown">
        <WarningDialog
          title="Delete report"
          onConfirm={() => {
            dispatch(deleteGraph(document_id));
            handleDeleteDocument(document_id, updateDoc);
          }}
          okText="Delete"
          cancelText="Cancel"
          closeDropdown={closeDropdown}
          text="textConfirmation"
        >
          <div className="menu-item-dropdown-delete"> Delete</div>
        </WarningDialog>
      </Menu.Item>
    </Menu>
  );
};

export default { MoreMenu, MoreMenu1 };
