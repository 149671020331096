import AccessDetailsForm from "./AccessDetailsForm";

const DetailsPane = () => {
  return (
    <div>
      <div className="left-sidebar-options-panel">
        <div className="left-sidebar-options-panel-mainHead">
          <div className="left-sidebar-options-panel-Header">
            <h2 className="left-sidebar-options-panel-Heading">Organisation</h2>
          </div>
          <AccessDetailsForm />
        </div>
      </div>
    </div>
  );
};

export default DetailsPane;
