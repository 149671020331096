import { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import "../../../assets/styles/metadata.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganisationMetadataKeys,
  getOrganisationMetadataProfiles,
} from "../../../store/actions/metadata";
import { keyColumns, profileColumns } from "./columns";
import MetadataTable from "./MetadataTable";
import { useMetadataState } from "../../../hooks/useApiState";
import NewMetadataForm from "./NewMetadataForm";
import { showSlideInView } from "../../../store/actions";
import { METADATA_KEYS, METADATA_PROFILES } from "../../../constants";
import PlusIcon from "../../../assets/svgs/entities/plus.svg";
import BlackKey from "../../../assets/svgs/entities/Blackkey.svg";
import GreyKey from "../../../assets/svgs/entities/Greykey.svg";
import greyreports from "../../../assets/svgs/entities/greyreports.svg";
import BlackReports from "../../../assets/svgs/entities/BlackReports.svg";

const Metadata = () => {
  const [activeTab, setActiveTab] = useState(METADATA_KEYS);

  const { metadata } = useSelector((state) => state);

  useMetadataState();

  const dispatch = useDispatch();
  useEffect(() => dispatch(getOrganisationMetadataKeys()), []);
  useEffect(() => dispatch(getOrganisationMetadataProfiles()), []);

  const showMetadataForm = () => {
    dispatch(
      showSlideInView(
        NewMetadataForm,
        { formType: activeTab },
        { title: "Add New Metadata " + activeTab, width: 500 }
      )
    );
  };

  const columns = activeTab === METADATA_KEYS ? keyColumns() : profileColumns();

  return (
    <>
      <div>
        <div className="left-sidebar-options-panel">
          <div className="left-sidebar-options-panel-mainHead">
            <div className="left-sidebar-options-panel-Header">
              <h2 className="left-sidebar-options-panel-Heading">Metadata</h2>
              <div className="rulesButton">
                <Button
                  className="rulesButton-addRule"
                  onClick={showMetadataForm}
                >
                  Add {activeTab === METADATA_KEYS ? "Keys" : "Profile"}
                  <img
                    src={PlusIcon}
                    alt="Plus-icon"
                    height={20}
                    width={20}
                    className="rulesButton-icon"
                  />
                </Button>
              </div>
            </div>
            <div className="metadata-sidebar">
              <Menu
                className="metadata-sidebar-head"
                onClick={(e) => setActiveTab(e.key)}
                selectedKeys={[activeTab]}
              >
                <Menu.Item
                  key={METADATA_KEYS}
                  className="metadata-sidebar-options"
                >
                  {activeTab === METADATA_KEYS ? (
                    <img src={BlackKey} alt="key-icon" height={16} width={16} />
                  ) : (
                    <img src={GreyKey} alt="key-icon" height={16} width={16} />
                  )}
                  <span
                    className="justify-between metadata-sidebar-options-value"
                    style={{
                      color:
                        activeTab === METADATA_KEYS ? "#101828" : "#98A2B3",
                    }}
                  >
                    {METADATA_KEYS}
                  </span>
                </Menu.Item>

                <Menu.Item
                  key={METADATA_PROFILES}
                  className="metadata-sidebar-options-profiles"
                >
                  {activeTab === METADATA_PROFILES ? (
                    <img
                      src={BlackReports}
                      alt="Reports-icon"
                      height={16}
                      width={16}
                    />
                  ) : (
                    <img
                      src={greyreports}
                      alt="Reports-icon"
                      height={16}
                      width={16}
                    />
                  )}
                  <span
                    className="justify-between metadata-sidebar-options-value"
                    style={{
                      color:
                        activeTab === METADATA_PROFILES ? "#101828" : "#98A2B3",
                    }}
                  >
                    {METADATA_PROFILES}
                  </span>
                </Menu.Item>
              </Menu>
            </div>
            <MetadataTable
              data={
                activeTab === METADATA_KEYS ? metadata.keys : metadata.profiles
              }
              columns={columns}
              activeTab={activeTab}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Metadata;
