import { Button, Input, Form } from "antd";
import { MinusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { CUSTOM_ADHOC, CUSTOM_KEY_SUFFIX } from "../../../constants";
import DisplayTip from "../../common/widgets/DisplayTip";
import ActionTip from "../../common/widgets/ActionTip";

export const keyColumns = () => [
  {
    title: <span style={{ paddingLeft: "24px" }}>Key</span>,
    dataIndex: "key",
    key: "key",
    editable: false,
    className: "title-column-id",
    render: (title) => <span className="tag-style-value">{title}</span>,
  },
  {
    title: "Default value",
    dataIndex: "default_value",
    key: "default_value",
    editable: true,
    align: "left",
    ellipsis: {
      showTitle: false,
    },
    className: "default-value-column",
    render: (title) => (
      <DisplayTip placement="topLeft" title={title}>
        <span className="buckets-description-list-item">{title}</span>
      </DisplayTip>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    editable: true,
    required: true,
    ellipsis: {
      showTitle: false,
    },
    align: "left",
    render: (description) => (
      <DisplayTip placement="topLeft" title={description}>
        <span className="buckets-description-list-item">{description}</span>
      </DisplayTip>
    ),
  },
];

export const profileColumns = () => [
  {
    title: <span style={{ paddingLeft: "24px" }}>Name</span>,
    dataIndex: "name",
    key: "name",
    className: "title-column-id",
    width: "303px",
    render: (title) => <span className="tag-style-value"> {title} </span>,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: {
      showTitle: false,
    },
    align: "left",
    render: (description) => (
      <DisplayTip placement="topLeft" title={description}>
        <span className="buckets-description-list-item">{description}</span>
      </DisplayTip>
    ),
  },
];

export const documentMetadataColumns = (removeAdhoc, deleteKey) => [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
    render: (key, record) => {
      if (key && key.startsWith(CUSTOM_ADHOC)) {
        return (
          <div className="align-center">
            <Button
              type="text"
              className="p-0 mr-5"
              onClick={() => removeAdhoc(key)}
            >
              <MinusCircleOutlined />
            </Button>
            <Form.Item name={key + CUSTOM_KEY_SUFFIX} noStyle>
              <Input placeholder="Adhoc key" />
            </Form.Item>
          </div>
        );
      } else {
        return (
          <div>
            <Button
              type="text"
              className="remove-key-btn p-0"
              danger
              onClick={() => deleteKey(key, record?.unsaved)}
            >
              <ActionTip title="Delete key">
                <DeleteOutlined />
              </ActionTip>
            </Button>
            <span>{key}</span>
          </div>
        );
      }
    },
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (value, record) => {
      return (
        <Form.Item
          name={record.key}
          initialValue={value || record.default_value}
          noStyle
        >
          <Input placeholder="value" />
        </Form.Item>
      );
    },
  },
];

export const profileKeysColumns = (deleteKey) => [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
    render: (key, record) => {
      if (record.unsaved) {
        return <span>{key}</span>;
      } else {
        return (
          <div>
            <Button
              type="text"
              className="remove-key-btn p-0"
              danger
              onClick={() => deleteKey(key, record.unsaved)}
            >
              <ActionTip title="Delete key">
                <DeleteOutlined />
              </ActionTip>
            </Button>
            <span>{key}</span>
          </div>
        );
      }
    },
  },
  {
    title: "Default Value",
    dataIndex: "default_value",
    key: "default_value",
    render: (value, record) => {
      return (
        <Form.Item name={record.key} initialValue={value} noStyle>
          <Input placeholder="Default value" />
        </Form.Item>
      );
    },
  },
];
