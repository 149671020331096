import { Input, Form } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  required,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          className="mx-20"
          rules={[
            {
              required,
              message: `Please input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
