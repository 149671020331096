import "../../../assets/styles/widgets.scss";
import ArrowUp from "../../../assets/svgs/entities/arrowup.svg";

const TotalStatWidget = ({ title, count, compare, altText }) => {
  const formattedCount = new Intl.NumberFormat("en-US").format(
    isNaN(count) ? 0 : count
  );
  const formattedCount1 = new Intl.NumberFormat("en-US").format(
    isNaN(compare) ? 0 : compare
  );

  return (
    <div className="total-stat-widget b-white">
      <div className="title">{title}</div>
      <div className="total-stat-widget-count">
        <div className="count">
          <span style={{ marginRight: "7px" }}>{formattedCount}</span>
        </div>
        <div className={`${altText ? "alttext" : "compare dynamic-width"} `}>
          {!!compare && (
            <div className="custom">
              <span className="compare-text">{formattedCount1}</span>
              <img
                src={ArrowUp}
                alt="ArrowUp"
                className="ArrowUp"
                height={12}
                width={12}
              />
            </div>
          )}

          {!!altText && !compare && altText}
        </div>
      </div>
    </div>
  );
};

export default TotalStatWidget;
